import React, {
  cloneElement,
  Fragment
} from 'react'
import { List, Datagrid, TextField, DateField, FormDataConsumer,
         useListContext, TopToolbar, sanitizeListRestProps, ReferenceInput, SelectInput , BooleanInput} from 'react-admin'
import { Edit,
         Create,
         SimpleForm,
         TextInput,
         Toolbar,
         CreateButton,
         ExportButton,
         SearchInput,
         Filter,
         SaveButton,
         ReferenceField } from 'react-admin'
import { required, email } from 'ra-core'
import { useForm } from 'react-final-form'
import ClearIcon from '@material-ui/icons/Clear'
import RaisedButton from 'material-ui/RaisedButton'

import constants from '../lib/constants'
import ImageUploadAWS from '../CustomActions/shared/ImageUploadAWS'

const ConsumerListActions = props => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, basePath, showFilter, total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={20000}
      />
    </TopToolbar>
  );
};

const ConsumerListFilter = (props) => (
  <Filter {...props}>
    <SearchInput placeholder="Filter by 'Name'" source="name" alwaysOn />
  </Filter>
)

export const ConsumerList = (props) => (
  <List {...props}
    actions={<ConsumerListActions />}
    filters={<ConsumerListFilter />}
    sort={{ field: 'name', order: 'ASC' }}
    perPage={25}
    bulkActionButtons={false} >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="email" />
        <ReferenceField source="organization_id" reference="organizations" label="Organization">
            <TextField source="name" />
        </ReferenceField>
        <DateField source="updated_at" locales={constants.LOCALE} />
        <DateField source="created_at" locales={constants.LOCALE} />
      </Datagrid>
  </List>
)

const CustomToolbar = (props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
))

const ClearImageButton = () => {
  const form = useForm()
  return <RaisedButton onClick={() => {form.change("logo", null)}} label="Clear" icon={<ClearIcon />} style={{float:'left'}} />
}

const ConsumerTitle = ({ record }) => {
  return <span>{record ? record.name : 'Nameless Consumer'}</span>
}

export const ConsumerEdit = props => {
  return (
  <Edit title={<ConsumerTitle />} {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput disabled source="id" />
      <ReferenceInput disabled source="organization_id" defaultValue={2} reference="organizations" label="Organization" perPage={-1} validate={required('Please select organization')}>
        <SelectInput
          optionText="name"
          optionValue="id" />
      </ReferenceInput>
      <TextInput source="name" validate={required(constants.FIELD_MANDATORY)} />
      <TextInput source="address" multiline />
      <BooleanInput source="hide_address" label="Hide Address on COAs?" />
      <TextInput source="email" validate={required(constants.FIELD_MANDATORY)} />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const logoUrl = `https://${process.env.REACT_APP_CONSUMER_LOGOS_BUCKET}.s3.us-east-2.amazonaws.com/${formData.logo}`
          const current = formData.logo ? (
            <div style={{backgroundColor: 'lightgrey', borderRadius: '8px', padding: '10px', textAlign: 'center'}}>
              <ClearImageButton />
              <p>Logo URL: <a href={logoUrl} target="_blank" rel="noopener noreferrer">{logoUrl}</a></p>
              <img alt="Consumer Logo" src={logoUrl} style={{maxWidth: '400px', height: 'auto'}} />
            </div>
          ) : ''
          return (
            <div>
              <h3>LOGO</h3>
              {current}
              <ImageUploadAWS
                formData = {formData}
                bucket = {process.env.REACT_APP_CONSUMER_LOGOS_BUCKET}
                accessKeyId = {process.env.REACT_APP_CONSUMER_LOGOS_ACCESS_KEY_ID}
                secretAccessKey = {process.env.REACT_APP_CONSUMER_LOGOS_SECRET_ACCESS_KEY}
                column = "logo"
                requiredField = {false}
              />
            </div>
          )
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
)}

export class ConsumerCreate extends React.Component {
  render() {
    return (
      <Create {...this.props}>
        <SimpleForm redirect={`/consumers`}>
          <TextInput disabled source="id" />
          <ReferenceInput source="organization_id" defaultValue={2} reference="organizations" label="Organization" perPage={-1} validate={required('Please select organization')}>
            <SelectInput
              optionText="name"
              optionValue="id" />
          </ReferenceInput>
          <TextInput source="name" validate={required(constants.FIELD_MANDATORY)} />
          <TextInput source="address" multiline />
          <BooleanInput source="hide_address" label="Hide Address on COAs?" />
          <TextInput source="email" validate={[required(constants.FIELD_MANDATORY),email("Please enter a valid email address")]} />
          <Fragment>
            <h3>LOGO</h3>
            <div style={{backgroundColor: 'lightgrey', borderRadius: '8px', padding: '10px', textAlign: 'center'}}>
              <p>Logo can be uploaded via editing Consumer after initial creation.</p>
            </div>
          </Fragment>
        </SimpleForm>
      </Create>
    )
  }
}
