import React, {
  cloneElement
} from 'react'
import { List, Datagrid, TextField,
         useListContext, TopToolbar,
         sanitizeListRestProps, useRecordContext,
         ExportButton, SearchInput,
         Filter } from 'react-admin'
import { Link } from 'react-router-dom'

const ConsumersCtsSummariesListActions = props => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, showFilter, total } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={20000}
      />
    </TopToolbar>
  );
};

const ConsumersCtsSummariesListFilter = (props) => (
  <Filter {...props}>
    <SearchInput placeholder="Filter by Consumer's Name" source="name" alwaysOn />
  </Filter>
)

const BalanceTextField = ({ source }) => {
  const record = useRecordContext()
  const style = record[source] < 0 ? {fontWeight: 'bold', color: 'red'} : record[source] > 0 ? {fontWeight: 'bold', color: 'green'} : {}
  return(<span style={style}>{record[source]}</span>)
}

const ConsumerLinkField = ({ source }) => {
  const record = useRecordContext()
  return(<Link to={`consumers/${record.id}`}>{record.name}</Link>)
}

export const ConsumersCtsSummariesList = (props) => (
  <List {...props}
    actions={<ConsumersCtsSummariesListActions />}
    filters={<ConsumersCtsSummariesListFilter />}
    sort={{ field: 'name', order: 'ASC' }}
    perPage={25}
    bulkActionButtons={false}>
      <Datagrid id="cts-balances">
        <TextField source="id" />
        <ConsumerLinkField source="name" label="Name" />
        <BalanceTextField source="twentyfour_balance" label="24hr Balance" />
        <BalanceTextField source="seventytwo_balance" label="72hr Balance" />
        <TextField source="purchase_twentyfour_total" label="24hr Total Purchases" />
        <TextField source="purchase_seventytwo_total" label="72hr Total Purchases" />
        <TextField source="submission_twentyfour_total" label="24hr Total Submissions" />
        <TextField source="submission_seventytwo_total" label="72hr Total Submissions" />
      </Datagrid>
  </List>
)
