import React, { 
  Fragment, 
  cloneElement, 
  useMemo, 
  useEffect, 
  useRef, 
  useState, 
  useCallback } from 'react';

import {
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  TextInput,
  Button,
  required
  } from 'react-admin'

import { useForm, useFormState } from 'react-final-form';

import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import _ from 'lodash';
import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';

import { Services } from '../../services/Services'

const spySubscription = { values: true };

function ModelQuickCreateButton({ onChange, showDialog, setShowDialog, model, fields }) {
  const [create, { loading }] = useCreate(`${model}s`);
  const notify = useNotify();
  const form = useForm();


  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          // Update the form to target the newly created post
          form.change(`${model}_id`, data.id);
          onChange(data);
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        }
      }
    );
  };

  let textInputs = fields.map(field => <TextInput source={field} validate={required()} fullWidth />)

  return (
    <>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={`Create ${model}`}
      >
        <DialogTitle>Create {model}</DialogTitle>

        <FormWithRedirect
          resource={`${model}s`}
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                {textInputs}
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}


const AutocompleteRef = ({ formData, model, fields, ...rest }) => {
  const [showDialog, setShowDialog] = useState(false);

  const [version, setVersion] = useState(0);
  const { values } = useFormState({ subscription: spySubscription });
  const handleChange = useCallback((data) => {
    setValue(data);
    setVersion(version + 1)
  }, [version]);

  const form = useForm();

  const [vale, setVale] = React.useState(null);
  
  const queries = useRef(new Array());

  const [value, setValue] = React.useState(formData[model]);
  const filter = createFilterOptions();
  const [options, setOptions] = React.useState([]);
  
  const fetcher = React.useMemo(
    () =>
      throttle((val, callback) => {
        if (!val) return false;
        if (queries.current.includes(val)) return false;
        
        queries.current.push(val)

        Services.search(model, val).then((response) => {
          callback(val, response.data)
        }).catch((error) => {
          console.log(error)
        })

      }, 600, { 'leading': false }),
    [],
  );

  useEffect(() => {
    let active = true;
    if (!queries.current.includes(vale)){
      fetcher(vale, (val, results) => {
        if (results === false) return false;
        var newOptions = _.chain([...options, ...results.data]).uniqBy('id').value()
        setOptions(newOptions);
      });
    }
  },[vale]);

  return (
    <Fragment>
      <Autocomplete
        value={(value && value.name) || ""}
        onInputChange={(event, newValue, reason) => {
          setVale(newValue);
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setValue({title: newValue,});
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setShowDialog(true)
            setValue({title: newValue.inputValue,});
          } else {
            form.change(`${model}_id`, newValue?.id)
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') { return option; }
          // Add "xxx" option created dynamically
          if (option.inputValue) { return option.inputValue; }
          // Regular option
          return option.name;
        }}
        renderOption={(option) => option.name}
        style={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label={_.capitalize(model)} variant="filled" required/>
        )}
      />
      
      <ModelQuickCreateButton model={model} fields={fields} showDialog={showDialog} setShowDialog={setShowDialog} onChange={handleChange} />
      
      <TextInput disabled fullWidth source={`${model}_id`} optionText="name" type="hidden" style={{ height: 0, overflow: "hidden" }} />
    </Fragment>
  );
};

export default AutocompleteRef
