import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { showNotification } from 'react-admin'
import { push } from 'connected-react-router'
import Modal from '../modal/Modal'
import { Services } from '../../services/Services'
import constants from '../../lib/constants'
class ActiveToggleButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }
  handleClick = (e) => {
    e.stopPropagation()
    const { showNotification } = this.props
    let requestData = { user: { is_active: !this.props.record.is_active } }
    Services.userActiveToggle(this.props.record.id, requestData).then((response) => {
      showNotification(this.props.record.is_active ? constants.USER.DEACTIVATE_SUCCESS : constants.USER.ACTIVATE_SUCCESS, 'info')
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }).catch((error) => {
      showNotification(constants.ERROR.SOMETHING_WENT_WRONG, 'warning')
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    })
    this.handleClose(e)
    return false
  }
  handleClickOpen = (e) => {
    e.stopPropagation()
    this.setState({
      open: true
    })
  }
  handleClose = (e) => {
    e.stopPropagation()
    this.setState({
      open: false
    })
  }

  render() {
    return <div>
      <Modal open={this.state.open}
        handleClose={this.handleClose}
        handleClick={this.handleClick}
        content={this.props.record.is_active ? constants.USER.DEACTIVATE_CONFIRMATION_CONTENT : constants.USER.ACTIVATE_CONFIRMATION_CONTENT}
        title={this.props.record.is_active ? constants.USER.DEACTIVATE_CONFIRMATION_TITLE : constants.USER.ACTIVATE_CONFIRMATION_TITLE}
      />
      <Button onClick={this.handleClickOpen} color="secondary" size="small">{this.props.record.is_active ? constants.USER.ACTIVATE_BUTTON_TITLE : constants.USER.DEACTIVATE_BUTTON_TITLE }</Button>
    </div>
  }
}

ActiveToggleButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
}

export default connect(null, {
  showNotification,
  push,
})(ActiveToggleButton)
