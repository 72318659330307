import React, {
  Component
} from 'react'
import {Services} from '../services/Services'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

export class MachinesDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
       machines: [],
       limit : 10,
       total_count:0,
       page: 0,
       searchQuery:'',
       sortColumn: undefined,
       sortOrder: undefined,
       startIndex: 0,
       endIndex: 10,
       loading:true
    }
    this.fetchData = this.fetchData.bind(this)
    this.onSortChange = this.onSortChange.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
  }
  componentDidMount() {
    this.fetchData()
  }


  fetchData() {
    Services.getMachines(this.state).then((response) => {
      let machines = response.data.data
      let meta = response.data.meta
      this.setState({
        machines: machines,
        total_count: meta.total_count,
        sortColumn: meta.sortColumn,
        sortOrder: meta.sortOrder,
        startIndex: meta.startIndex,
        endIndex: meta.endIndex

      })
    }).catch((error) => {
      console.log(error)
    })
  }

  onSortChange(sortColumn, sortOrder) {
    this.setState({
      sortColumn,
      sortOrder
    }, () => {
      this.fetchData() 
    })
  }

  formatLampTime(minutes) {
    return `${Math.floor(minutes / 60).toLocaleString()}h ${minutes % 60}m`
  }
  
  handleChangeRowsPerPage(event) {
    this.setState({
      limit: parseInt(event.target.value, 10),
    }, () => {
      this.handleChangePage(event, 0)
    })
  }

  handleChangePage(event, newPage) {
    this.setState({
      page: newPage,
      startIndex: (newPage * this.state.limit) ,
      endIndex: (newPage * this.state.limit) + this.state.limit
    }, () => {
      this.fetchData() 
    })

  }


  render() {
  	return (
      <Paper  style={{marginBottom: '2rem'}}>
        <div>
          <div style={{padding: '1rem'}}>
            <h2>Machines</h2>
          </div>
          <Table aria-label="customized table" id="mach-table">
            <TableHead>
              <TableRow>
                <TableCell key = "name">
                  <TableSortLabel>
                    Machine Name
                  </TableSortLabel>
                </TableCell>
                <TableCell key = "organization" >Organization</TableCell>
                <TableCell >Samples Run</TableCell>
                <TableCell key = "machine_lamp.minutes" align="right">Lamp Time</TableCell>
                <TableCell key = "machine_lamp.ignitions" align="right">Lamp Ignitions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.machines.map(machine => (
                <TableRow key={machine.id}>
                  <TableCell >
                    {machine.name}
                  </TableCell>
                  <TableCell >{machine.organization.name}</TableCell>
                  <TableCell >{machine.organization.samples}</TableCell>
                  <TableCell align="right">{machine.machine_lamp?this.formatLampTime(machine.machine_lamp.minutes):'No data'}</TableCell>
                  <TableCell align="right">{machine.machine_lamp?machine.machine_lamp.ignitions:'No Data'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={this.state.total_count}
          rowsPerPage={this.state.limit}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    )
  }
}
