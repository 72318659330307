import React, { useState, useEffect } from 'react'
import LensIcon from '@material-ui/icons/Lens';
import { Services } from '../services/Services'


const QuickBooksStatus = (props) => {
	const [qbs, setQbs] = useState(false)

	useEffect(()=>{
		Services.getQBooksLoggedIn().then((response) => {
      	setQbs(response.data)
    	})
	}, [])

	let color = qbs ? "action" : "error"

	return(
		<div style={{marginLeft: '80%'}}>QuickBooks Connection: <span><LensIcon color={color} fontSize="inherit"/></span></div>
		)
}

export default QuickBooksStatus
