import React from 'react'
import { List, Edit, Datagrid, TextInput, TextField, DateField } from 'react-admin'
import { Create, SimpleForm } from 'react-admin'
import { required } from 'ra-core'
import constants from './lib/constants'
const SampleTypeTitle = ({ record }) => {
  return <span>{record ? record.name : 'Nameless Sample Type'}</span>
}
const isInteger = (message = 'Must be a integer') =>
  value => value % 1 === 0 ? undefined : message
const validateNumber = [required('Please fill the field'), isInteger()]

export const SampleTypesList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField label="Name" source="name" sortable={false} />
      <TextField label="Recommended Sample Size" source="recommended_size" sortable={false} />
      <TextField label="% Relative Standard Deviation" source="rsd_percent" sortable={false} />
      <TextField label="Order" source="rank" type="number" />
      <DateField source="updated_at" locales={constants.LOCALE} />
      <DateField source="created_at" locales={constants.LOCALE} />
    </Datagrid>
  </List>
)
export const SampleTypesCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required('Please fill the field')} />
      <TextInput source="recommended_size" label="Recommended Sample Size" />
      <TextInput source="rsd_percent" label="% Relative Standard Deviation" />
      <TextInput source="rank" label="Order" type="number" validate={validateNumber} />
    </SimpleForm>
  </Create>
)
export const SampleTypesEdit = props => (
  <Edit title={<SampleTypeTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required('Please fill the field')} />
      <TextInput source="recommended_size" label="Recommended Sample Size" />
      <TextInput source="rsd_percent" label="% Relative Standard Deviation" />
      <TextInput source="rank" label="Order" type="number" validate={validateNumber} />
    </SimpleForm>
  </Edit>
)
