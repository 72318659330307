import React from 'react'
import { List, Datagrid, TextField, DateField, ReferenceInput } from 'react-admin'
import { Edit, Create, SimpleForm, TextInput, SelectInput } from 'react-admin'
import { required } from 'ra-core'
import constants from './lib/constants'
const OrganizationOfficerTitle = ({ record }) => {
  return <span>{record ? record.name : 'Nameless OrganizationOfficer'}</span>
}

export const OrganizationOfficerList = props => (
  <List {...props} bulkActionButtons={false} perPage={25} title="Organization Officers">
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="organization.name" label="Officer Organization" />
      <TextField source="private_organization.name" label="Private Organization" />
      <DateField source="updated_at" locales={constants.LOCALE} />
      <DateField source="created_at" locales={constants.LOCALE} />
    </Datagrid>
  </List>
)

const redirect = (basePath, id, data) => `/organization_officers/${id}`

export const OrganizationOfficerEdit = props => (
  <Edit title={<OrganizationOfficerTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput source="organization.id" reference="organizations" label="Officer Organization" perPage={-1} validate={required('Please select organization')}>
        <SelectInput
          optionText="name"
          optionValue="id" />
      </ReferenceInput>
      <ReferenceInput source="private_organization.id" reference="organizations" label="Private Organization" perPage={-1} validate={required('Please select organization')}>
        <SelectInput
          optionText="name"
          optionValue="id" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)

export class OrganizationOfficerCreate extends React.Component {
  render() {
    return (
      <Create {...this.props}>
        <SimpleForm redirect={redirect}>
          <ReferenceInput source="organization.id" reference="organizations" label="Officer Organization" perPage={-1} validate={required('Please select organization')}>
            <SelectInput
              optionText="name"
              optionValue="id" />
          </ReferenceInput>
          <ReferenceInput source="private_organization.id" reference="organizations" label="Private Organization" perPage={-1} validate={required('Please select organization')}>
            <SelectInput
              optionText="name"
              optionValue="id" />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    )
  }
}
