import React, {
  Component
} from 'react'
import {Services} from '../services/Services'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'

export class OrgTestSummaryDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
       summaries: [],
       total_count:0,
       sortValue: 'organization_name',
       sortDirection: 'asc'
    }
    this.fetchData = this.fetchData.bind(this)
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    Services.getOrgTestSummary().then((response) => {
      let orgSummaries = response.data.data
      let meta = response.data.meta
      this.setState({
        summaries: orgSummaries,
        total_count: meta.total_count

      })
    }).catch((error) => {
      console.log(error)
    })
  }

  sortTable = (column) => {
    const summaries = this.state.summaries
    const direction = this.state.sortDirection === 'asc' ? 'desc' : 'asc'
    if (direction === 'desc') {
      summaries.sort((a, b) => {
        if ( b[column] < a[column] ){
          return -1
        }
        if ( b[column] > a[column] ){
          return 1
        }
        return 0
      })
    } else {
      summaries.sort((a, b) => {
        if ( a[column] < b[column] ){
          return -1
        }
        if ( a[column] > b[column] ){
          return 1
        }
        return 0
      })
    }
    
    this.setState({
      summaries: summaries,
      sortValue: column,
      sortDirection: direction
    })
  }

  handleMonth = (monthNum) => {
    let newNum = monthNum
    if (monthNum < 0) {
      newNum = 12 + monthNum
    }
    return newNum
  }

  render() {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const date = new Date()
    const thisMonth = monthNames[date.getMonth()]
    const lastMonth = monthNames[this.handleMonth(date.getMonth() - 1)]
    const twoMonthsAgo = monthNames[this.handleMonth(date.getMonth() - 2)]
    const threeMonthsAgo = monthNames[this.handleMonth(date.getMonth() - 3)]

  	return (
    <Paper style={{marginBottom: '2rem'}}>
      <div>
        <div style={{padding: '1rem'}}>
          <h2>Total Tests Run per Organization - {date.toLocaleString()}</h2>
        </div>
        <Table aria-label="customized table" id="ots-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel 
                  active={this.state.sortValue === 'organization_name'}
                  direction={this.state.sortDirection}
                  onClick={() => this.sortTable('organization_name')}>
                  Org Name
                </TableSortLabel>
              </TableCell>

              <TableCell align='center'>
                <TableSortLabel 
                  active={this.state.sortValue === 'this_month_sofar'}
                  direction={this.state.sortDirection}
                  onClick={() => this.sortTable('this_month_sofar')}>
                  {thisMonth}
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel 
                  active={this.state.sortValue === 'last_month'}
                  direction={this.state.sortDirection}
                  onClick={() => this.sortTable('last_month')}>
                  {lastMonth}
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel 
                  active={this.state.sortValue === 'two_months_ago'}
                  direction={this.state.sortDirection}
                  onClick={() => this.sortTable('two_months_ago')}>
                  {twoMonthsAgo}
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel 
                  active={this.state.sortValue === 'three_months_ago'}
                  direction={this.state.sortDirection}
                  onClick={() => this.sortTable('three_months_ago')}>
                  {threeMonthsAgo}
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel 
                  active={this.state.sortValue === 'total_one_day'}
                  direction={this.state.sortDirection}
                  onClick={() => this.sortTable('total_one_day')}>
                  Day
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel 
                  active={this.state.sortValue === 'total_seven_days'}
                  direction={this.state.sortDirection}
                  onClick={() => this.sortTable('total_seven_days')}>
                  Week
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel 
                  active={this.state.sortValue === 'total_thirty_days'}
                  direction={this.state.sortDirection}
                  onClick={() => this.sortTable('total_thirty_days')}>
                  30 Days
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel 
                  active={this.state.sortValue === 'total_sixty_days'}
                  direction={this.state.sortDirection}
                  onClick={() => this.sortTable('total_sixty_days')}>
                  60 Days
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel 
                  active={this.state.sortValue === 'total_ninety_days'}
                  direction={this.state.sortDirection}
                  onClick={() => this.sortTable('total_ninety_days')}>
                  90 Days
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel 
                  active={this.state.sortValue === 'total_year_to_date'}
                  direction={this.state.sortDirection}
                  onClick={() => this.sortTable('total_year_to_date')}
                  className='long-label'>
                  Year to Date
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel 
                  active={this.state.sortValue === 'total_forever'}
                  direction={this.state.sortDirection}
                  onClick={() => this.sortTable('total_forever')}>
                  Forever
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel 
                  active={this.state.sortValue === 'days_since_most_recent_test'}
                  direction={this.state.sortDirection}
                  onClick={() => this.sortTable('days_since_most_recent_test')}
                  className='long-label'>
                  Days Since Last Test
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel 
                  active={this.state.sortValue === 'most_recent_test_date'}
                  direction={this.state.sortDirection}
                  onClick={() => this.sortTable('most_recent_test_date')}
                  className='long-label'>
                  Last Test Date (UTC)
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.summaries.map(summary => (
              <TableRow key={summary.organization_id}>
                <TableCell>{summary.organization_name}</TableCell>
                <TableCell align='center'><Tooltip title={thisMonth}><p>{summary.this_month_sofar}</p></Tooltip></TableCell>
                <TableCell align='center'><Tooltip title={lastMonth}><p>{summary.last_month}</p></Tooltip></TableCell>
                <TableCell align='center'><Tooltip title={twoMonthsAgo}><p>{summary.two_months_ago}</p></Tooltip></TableCell>
                <TableCell align='center'><Tooltip title={threeMonthsAgo}><p>{summary.three_months_ago}</p></Tooltip></TableCell>
                <TableCell align='center'><Tooltip title='Day'><p>{summary.total_one_day}</p></Tooltip></TableCell>
                <TableCell align='center'><Tooltip title='Week'><p>{summary.total_seven_days}</p></Tooltip></TableCell>
                <TableCell align='center'><Tooltip title='30 Days'><p>{summary.total_thirty_days}</p></Tooltip></TableCell>
                <TableCell align='center'><Tooltip title='60 Days'><p>{summary.total_sixty_days}</p></Tooltip></TableCell>
                <TableCell align='center'><Tooltip title='90 Days'><p>{summary.total_ninety_days}</p></Tooltip></TableCell>
                <TableCell align='center'><Tooltip title='Year to Date'><p>{summary.year_to_date}</p></Tooltip></TableCell>
                <TableCell align='center'><Tooltip title='Forever'><p>{summary.total_forever}</p></Tooltip></TableCell>
                <TableCell align='center'><Tooltip title='Days Since Most Recent Test'><p>{summary.days_since_most_recent_test}</p></Tooltip></TableCell>
                <TableCell align='center'><Tooltip title='Most Recent Test Date (UTC)'><p className='long-label'>{new Date(summary.most_recent_test_date).toUTCString()}</p></Tooltip></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
    )
  }
}
