import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { showNotification } from 'react-admin'
import { push } from 'connected-react-router'

class DuplicateButton extends Component {
  handleClick = (e) => {
    e.stopPropagation()
    const { push, record, showNotification } = this.props
    const request = new Request(`${process.env.REACT_APP_API_ROOT}/admin/samples/${record.id}/duplicate`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      })
    })
    fetch(request)
      .then((response) => {
        return response.json()
      })
      .then((sample) => {
        push('/samples/' + sample.id)
        showNotification('Sample duplicated')
      })
      .catch((e) => {
        console.error(e)
        showNotification('Error: sample could not be duplicated', 'warning')
      })
    return false
  }

  render() {
    return <Button onClick={this.handleClick}>Duplicate</Button>
  }
}

DuplicateButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
}

export default connect(null, {
  showNotification,
  push,
})(DuplicateButton)
