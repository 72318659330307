import React from 'react'
import { Filter, List, Datagrid, TextField, EmailField, DateField, downloadCSV } from 'react-admin'
import { Edit, Create, SimpleForm, TextInput, ReferenceInput, SelectInput, BooleanInput } from 'react-admin'
import { required } from 'ra-core'
import ActiveToggleButton from './CustomActions/users/ActiveToggleButton'
import ResetPasswordButton from './CustomActions/users/ResetPasswordButton'
import LogoutFromAllButton from './CustomActions/users/LogoutFromAllButton'
import constants from './lib/constants'
import jsonExport from 'jsonexport/dist'
let re_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const email = (message = 'Must be a valid email id') =>
  value => value && !re_email.test(String(value).toLowerCase()) ? message : undefined
const validateEmail = [required('Please fill the field'), email()]
let re_password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
const password = (message = constants.USER.PASSWORD_STRENGTH) =>
  value => (value && (re_password.test(value) === false || String(value).length < 8)) ? message : undefined
const validatePassword = [required('Please fill the field'), password()]
const validateUpdatePassword = [password()]
const NameTitle = ({ record }) => {
  return <span>{record ? record.name : 'Nameless'}</span>
}

const exporter = users => {
  const usersForExport = users.map(user => {
    let userForExport = user
    userForExport['role'] = user.role.name
    userForExport['organization'] = user.organization.name
    return userForExport
  })
  jsonExport(usersForExport, {
    headers: ['name', 'email', 'role', 'organization', 'alternate_email_id', 'contact_number']
  }, (err, csv) => {
    downloadCSV(csv, 'users')
  })
}

const SampleListFilter = (props) => (
    <Filter {...props}>
      <BooleanInput label="Active" source="is_active" alwaysOn />
      <ReferenceInput source="organization_id" reference="organizations" perPage={-1} alwaysOn >
        <SelectInput  label="Organization" source="organization_id" reference="organizations" />
      </ReferenceInput>
    </Filter>
)

export const SubField = props => (
  (
    <TextField {...props} source = {props.source+'.name'} />
  )
)
export const UserList = props => (
  <List filters={<SampleListFilter />} {...props} exporter={exporter} bulkActionButtons={false} perPage={25} filterDefaultValues={{ is_active: true }}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <EmailField source="email" />
      <SubField source="role" label="Role" />
      <SubField source="organization" />
      <EmailField source="alternate_email_id" label='Alt Email ID' />
      <TextField source="contact_number" />
      <DateField source="updated_at" locales={constants.LOCALE} />
      <DateField source="created_at" locales={constants.LOCALE} />
      <ActiveToggleButton />
      <ResetPasswordButton />
      <LogoutFromAllButton />
    </Datagrid>
  </List>
)

export class UserEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      setVisiblePassword: true,
      setApplicationRoles: []
    }
  }
  componentDidMount() {

  }
  render() {
    return (
      <Edit title={<NameTitle />} {...this.props}>
        <SimpleForm>
          <TextInput disabled source="id" />
          <TextInput source="name" validate={required('Please fill the field')} />
          <TextInput source="email" validate={validateEmail} />
          <TextInput source="password" validate={validateUpdatePassword} />
          <ReferenceInput source="role.id" reference="roles" label="Role" perPage={-1} validate={required('Please select role')}>
            <SelectInput optionText="name"/>
          </ReferenceInput>
          <ReferenceInput source="organization.id" reference="organizations" label="Organization" perPage={-1} validate={required('Please select organization')}>
            <SelectInput optionText="name"/>
          </ReferenceInput>
          <TextInput source="alternate_email_id" />
          <TextInput source="contact_number" />
          <SelectInput source="certus" label="VERISEAL Approval for SuperAdmin" choices={[
            {id: null, name: 'None (Admin)'},
            {id: 'veriseal', name: 'VERISEAL'},
            {id: 'veriseal-coa', name: 'VERISEAL COA'}
          ]}/>
        </SimpleForm>
      </Edit>
    )
  }
}

const redirect = (basePath, id, data) => `/users`

export class UserCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      setApplicationRoles: []
    }
  }
  componentDidMount() {

  }
  render() {
    return (
      <Create {...this.props}>
        <SimpleForm redirect={redirect}>
          <TextInput source="name" validate={required('Please fill the field')} />
          <TextInput source="email" validate={validateEmail} />
          <ReferenceInput source="role.id" reference="roles" label="Role" perPage={-1} validate={required('Please select role')}>
            <SelectInput
              source="role_id"
              choices={this.state.setApplicationRoles}
              optionText="name"
              optionValue="id"
              validate={required('Please select role')}
            />
          </ReferenceInput>
          <TextInput source="password" validate={validatePassword} />
          <ReferenceInput source="organization_id" reference="organizations" label="Organization" perPage={-1} validate={required('Please select organization')}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="alternate_email_id" />
          <TextInput source="contact_number" />
          <SelectInput source="certus" label="VERISEAL Approval for SuperAdmin" choices={[
            {id: null, name: 'None (Admin)'},
            {id: 'veriseal', name: 'VERISEAL'},
            {id: 'veriseal-coa', name: 'VERISEAL COA'}
          ]}/>
        </SimpleForm>
      </Create>
    )
  }
}
