import React from 'react'
import { AppBar } from 'react-admin';
import QuickBooksStatus from './QuickBooksStatus'
import Box from '@material-ui/core/Box';
const CustomAppBar = (props) => {
	return(
		<AppBar {...props} >
			<QuickBooksStatus />
		</AppBar>)
}
export default CustomAppBar
