import React, {
  cloneElement
} from 'react';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  Edit,
  Create,
  SimpleForm,
  TabbedForm,
  FormTab,
  TextInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  TopToolbar,
  useListContext,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  required
} from 'react-admin'
import { useForm } from 'react-final-form'
import ClearIcon from '@material-ui/icons/Clear'
import RaisedButton from 'material-ui/RaisedButton'

import Consumer from './CustomActions/shared/AutocompleteReference'
import ImageUploadAWS from './CustomActions/shared/ImageUploadAWS'

import './samples.css'

import DuplicateButton from './samples/DuplicateButton'
import jsonFormat from 'json-format'
import constants from './lib/constants'

const jsonToTextArea = (json) => {
  if (json) {
    return jsonFormat(json)
  } else {
    return ''
  }
}

const textAreaToJson = (json) => {
  if (json) {
    return JSON.parse(json)
  } else {
    return null
  }
}

const choices = [
  {_id:'THC', name:'THC'},
  {_id:'CBDV', name:'CBDV'},
  {_id:'CBDVA', name:'CBDVA'},
  {_id:'THCV', name:'THCV'},
  {_id:'CBD', name:'CBD'},
  {_id:'CBG', name:'CBG'},
  {_id:'CBDA', name:'CBDA'},
  {_id:'CBGA', name:'CBGA'},
  {_id:'CBN', name:'CBN'},
  {_id:'THCD9', name:'THCD9'},
  {_id:'THCD8', name:'THCD8'},
  {_id:'CBC', name:'CBC'},
  {_id:'CBNA', name:'CBNA'},
  {_id:'THCA', name:'THCA'},
  {_id:'CBCA', name:'CBCA'}
];

const NameTitle = ({ record }) => {
  if (record && record.sample_type.slug === "calibration") {
    return <span>Calibration</span>
  } else if (record && record.intake_form && record.intake_form.strain_name) {
    return <span>{record.intake_form.strain_name}</span>
  } else {
    return <span>No Name</span>
  }
}

const SampleListFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="organization_id" reference="organizations" perPage={-1} alwaysOn>
      <SelectInput  label="Organization" source="organization_id" reference="organizations" />
    </ReferenceInput>
  </Filter>
)

const SampleListActions = props => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, basePath, showFilter, total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={20000}
      />
    </TopToolbar>
  );
};

export const SampleList = props => (
  <List {...props} actions={<SampleListActions />} filters={<SampleListFilter />} sort={{ field: 'created_at', order: 'DESC' }} perPage={25} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField label="Intake #" source="intake_form.intake_number" sortable={false} />
      <TextField label="Strain Name" source="intake_form.strain_name" sortable={false} />
      <TextField label="Type" source="sample_type.name" sortable={false} />
      <TextField label="Organization" source="organization.name" sortable={false} />
      <TextField label="Machine" source="machine.name" sortable={false} />
      <DateField source="created_at" locales={constants.LOCALE} />
      <DuplicateButton />
    </Datagrid>
  </List>
)

const optionRenderer = choice => `${choice.name} (${choice.email})`;

const ClearImageButton = () => {
  const form = useForm()
  return <RaisedButton onClick={() => {form.change("sample_image_url", null)}} label="Clear" icon={<ClearIcon />} style={{float:'left'}} />
}

export const SampleEdit = (props) => {
  return (
    <Edit title={<NameTitle />} {...props} >
      <TabbedForm>
        <FormTab label="Info">
          <TextInput disabled fullWidth source="intake_form" multiline resettable format={jsonToTextArea} parse={textAreaToJson} />
          <ReferenceInput disabled source="sample_type.id" reference="sample-types" label="Sample Type" perPage={-1}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput disabled source="user.id" reference="users" label="User" perPage={-1}>
            <SelectInput optionText="email" />
          </ReferenceInput>
          <ReferenceInput disabled source="organization.id" reference="organizations" label="Organization" perPage={-1}>
            <SelectInput optionText="name" />
          </ReferenceInput>
        </FormTab>
        <FormTab label="Results">
          <TextInput fullWidth source="results" multiline resettable format={(jsonResults) => (JSON.stringify(jsonResults))} />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export const SampleCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="intake_form" resettable format={jsonToTextArea} parse={textAreaToJson} />
      <TextInput source="coa" resettable format={jsonToTextArea} parse={textAreaToJson} />
      <TextInput source="results" resettable format={jsonToTextArea} parse={textAreaToJson} />
      <ReferenceInput source="user.id" reference="users" perPage={-1}>
        <SelectInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput source="organization.id" reference="organizations" label="Organization" perPage={-1}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="sample_type.id" reference="sample-types" label="Sample Type" perPage={-1}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)
