import AWS from 'aws-sdk'
import { useForm } from 'react-final-form';
import { v4 as uuidv4 } from 'uuid';

import React, {
  Fragment,
} from 'react';

import {
	ImageField,
	ImageInput,
	required
} from 'react-admin'

const ImageUploadAWS = ({ formData, bucket, accessKeyId, secretAccessKey, column, requiredField, ...rest }) => {

	const s3 = new AWS.S3({
		accessKeyId: accessKeyId,
		secretAccessKey: secretAccessKey,
		region: 'us-east-2'
	})

	function sendFile(file, key, element) {
	  s3.getSignedUrl('putObject', {
	    Bucket: bucket,
	    Key: key,
	    ACL: 'public-read',
	    ContentType: 'application/octet-stream'
	  });

	  s3.upload({
	    Bucket: bucket,
	    Key: key,
	    Body: file,
	    ContentType: file.type,
	    ACL: 'public-read'
	  }, function(err, data) {
	    if (err) {
	      // error('Something went wrong');
		  console.log('S3 upload error', err)
		  alert(`Image upload error: ${err}`)
	      return 'UPLOAD ERROR';
	    }
	  });
	  return `https://${bucket}.s3.us-east-2.amazonaws.com/${key}`;
	}

  	const form = useForm();
  
	const onDrop = (fileArray) => {
		for (var i = 0; i < fileArray.length; i++) {
			var ext = fileArray[i].name.split('.').pop()
			var key = `${formData.id}/${uuidv4()}.${ext}`
			sendFile(fileArray[i], key)
		}
		
		setTimeout(() => {form.change(column, key)}, 1000)
	}

	return (
		<Fragment>
			<ImageInput source="img"
				label="WARNING: Selecting an image will automatically upload but still requires pressing 'SAVE'"
				options={{onDrop: onDrop}}
				validate={requiredField && !formData[column] ? required() : null}
				multiple={false} >
				<ImageField source="img" title="title"/>
			</ImageInput>
		</Fragment>
	);
};


export default ImageUploadAWS;
