import React from 'react'

import {Edit, SimpleForm, TextInput} from 'react-admin'
import { List, Datagrid, TextField, NumberField, ReferenceInput, SelectInput, NumberInput, FormDataConsumer, required } from 'react-admin'
import { Create } from 'react-admin'
import { Services } from './services/Services'
const redirect = `/tokenbanks`

let choices

function getFilteredJuri(region_id) {
    Services.getJurisdictions().then((response) => {
        let values = response.data
        let filtered_hash = values.data.filter(object => object.region_id === region_id )
        choices = filtered_hash.map(({juri_id, juri}) =>({id: juri_id, name: juri}))
        return choices
	})
}

export const TokenbankEdit = props => (
    <Edit {...props}>
        <SimpleForm redirect={redirect} >
            <TextInput source="id" disabled/>
            <TextInput source="org_name" label="Organization" disabled/>
            <TextInput source="region" disabled/>
            <TextInput source="juri" label="Jurisdiction" disabled/>
            <NumberInput source="quantity" disabled/>
        </SimpleForm>
    </Edit>
);


export const TokenbankList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField  source="org_nam" label="Organization"/>
            <TextField source="region" />
            <TextField source="juri" label="Jurisdiction" />
            <NumberField source="token_cost" label="Total Token Price" options={{ style: 'currency', currency: 'USD' }} disabled/>
            <NumberField source="quantity" disabled/>
        </Datagrid>
    </List>
);

export const TokenbankCreate = props => {
    return (
		<Create {...props}>
            <SimpleForm>
                <ReferenceInput source="org_id"  label="Organization" reference="assigntokenbank" validate={[required()]} >
                    <SelectInput optionText="name"  optionValue="id" />
                </ReferenceInput>
                <FormDataConsumer>
                    {
                        ({
							formData, // The whole form data
							scopedFormData, // The data for this item of the ArrayInput
							getSource, // A function to get the valid source inside an ArrayInput
							...rest
                        }) => {return (
							<div>
								<ReferenceInput
									source="region_id"
									reference="certusregions"
									validate={[required()]} >
									<SelectInput
										label="Region"
										source="regions_id"
										reference="region_juris"
										onChange={getFilteredJuri(formData.region_id)}
									/>
								</ReferenceInput>
								<br/>
								<SelectInput source="juri_id" label="Jurisdiction" choices={choices} onChange={() => {}} validate={[required()]} />
							</div>
						)}
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
	)
};
