import React, {
  cloneElement
} from 'react'
import { List, Datagrid, TextField, DateField,
        //  EmailField,
         useListContext, TopToolbar, sanitizeListRestProps, ReferenceInput, SelectInput } from 'react-admin'
import { Edit,
         Create,
         SimpleForm,
         TextInput,
         DateInput,
         NumberInput,
         Toolbar,
         CreateButton,
         ExportButton,
         SearchInput,
         Filter,
         SaveButton } from 'react-admin'
import { required, email, minLength, maxLength, minValue } from 'ra-core'

import constants from '../lib/constants'
import ConsumerReferenceInputInline from './ConsumerReferenceInputInline'


const ConsumerSubmissionListActions = props => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, basePath, showFilter, total } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={20000}
      />
    </TopToolbar>
  );
};

const ConsumerSubmissionListFilter = (props) => (
  <Filter {...props}>
    <SearchInput placeholder="Filter by Consumer's Name" source="consumer" alwaysOn />
  </Filter>
)

export const ConsumerSubmissionList = (props) => (
  <List {...props}
    actions={<ConsumerSubmissionListActions />}
    filters={<ConsumerSubmissionListFilter />}
    sort={{ field: 'submission_date', order: 'DESC' }}
    perPage={25}
    bulkActionButtons={false} >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="consumer" />
        <TextField source="purchase_order_number" label="Purchase Order #" />
        <TextField source="ilcts_number" label="ILCTS #" />
        <DateField source="submission_date" locales={constants.LOCALE} label="Submission Date" />
        {/* <TextField source="first_name" />
        <TextField source="last_name" />
        <EmailField source="email" />
        <TextField source="phone" /> */}
        <TextField source="twenty_four_hour_quantity" label="Quantity: 24 Hour" />
        <TextField source="seventy_two_hour_quantity" label="Quantity: 72 Hour" />
        <DateField source="updated_at" locales={constants.LOCALE} />
        <DateField source="created_at" locales={constants.LOCALE} />
      </Datagrid>
  </List>
)

const CustomToolbar = (props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
))

const ConsumerSubmissionTitle = ({ record }) => {
  return <span>{record.consumer}: {record.purchase_order_number} - {record.ilcts_number}</span>
}

export const ConsumerSubmissionEdit = props => {
  return (
  <Edit title={<ConsumerSubmissionTitle />} {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
    <TextInput disabled source="id" />
      <ReferenceInput source="consumer_id" reference="consumers" label="Consumer" perPage={-1} validate={required('Please select consumer')}>
        <SelectInput
          optionText="name"
          optionValue="id" />
      </ReferenceInput>
      <NumberInput source="purchase_order_number" label="Purchase Order Number" min={0} step={1} validate={[required(constants.FIELD_MANDATORY),minValue(0, "Must be >= 0")]} />
      <TextInput source="ilcts_number" label="ILCTS Number" validate={required(constants.FIELD_MANDATORY)} />
      <DateInput source="submission_date" locales={constants.LOCALE} label="Submission Date" validate={required(constants.FIELD_MANDATORY)} />
      <TextInput source="first_name" label="First Name" validate={required(constants.FIELD_MANDATORY)} />
      <TextInput source="last_name" label="Last Name" validate={required(constants.FIELD_MANDATORY)} />
      <TextInput source="email" label="Email" validate={[required(constants.FIELD_MANDATORY),email("Please enter a valid email address")]}  />
      <TextInput source="phone" label="Phone" validate={[required(constants.FIELD_MANDATORY),minLength(12, "Minimum length 12 characters. Expected format: '123-456-7890'"),maxLength(12, "Max length 12 characters. Expected format: '123-456-7890'")]} />
      <NumberInput source="twenty_four_hour_quantity" label="Quantity: 24 Hour" min={0} step={1} validate={[required(constants.FIELD_MANDATORY),minValue(0, "Must be >= 0")]} />
      <NumberInput source="seventy_two_hour_quantity" label="Quantity: 72 Hour" min={0} step={1} validate={[required(constants.FIELD_MANDATORY),minValue(0, "Must be >= 0")]} />
    </SimpleForm>
  </Edit>
)}

const redirect = (basePath, id, data) => `/consumer_submissions`

export const ConsumerSubmissionCreate  = (props) => (
  <Create {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput disabled source="id" />
      <ConsumerReferenceInputInline
        source="consumer_id"
        reference={constants.RESOURCE_CONSUMERS}
        validate={required('Please select consumer')}
        label="Consumer"
        perPage={-1}
      />
      <NumberInput source="purchase_order_number" label="Purchase Order Number" min={0} step={1} validate={[required(constants.FIELD_MANDATORY),minValue(0, "Must be >= 0")]} />
      <TextInput source="ilcts_number" label="ILCTS Number" validate={required(constants.FIELD_MANDATORY)} />
      <DateInput source="submission_date" locales={constants.LOCALE} label="Submission Date" validate={required(constants.FIELD_MANDATORY)} />
      <TextInput source="first_name" label="First Name" validate={required(constants.FIELD_MANDATORY)} />
      <TextInput source="last_name" label="Last Name" validate={required(constants.FIELD_MANDATORY)} />
      <TextInput source="email" label="Email" validate={[required(constants.FIELD_MANDATORY),email("Please enter a valid email address")]} />
      <TextInput source="phone" label="Phone" validate={[required(constants.FIELD_MANDATORY),minLength(12, "Minimum length 12 characters. Expected format: '123-456-7890'"),maxLength(12, "Maximum length 12 characters. Expected format: '123-456-7890'")]} />
      <NumberInput source="twenty_four_hour_quantity" label="Quantity: 24 Hour" min={0} step={1} validate={[required(constants.FIELD_MANDATORY),minValue(0, "Must be >= 0")]} />
      <NumberInput source="seventy_two_hour_quantity" label="Quantity: 72 Hour" min={0} step={1} validate={[required(constants.FIELD_MANDATORY),minValue(0, "Must be >= 0")]} />
    </SimpleForm>
  </Create>
)
