import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { showNotification } from 'react-admin'
import { push } from 'connected-react-router'
import Modal from '../modal/Modal'
import { Services } from '../../services/Services'
import constants from '../../lib/constants'
class ResetPasswordButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }
  handleClick = (e) => {
    e.stopPropagation()
    const { push, showNotification } = this.props
    let requestData = { email: this.props.record.email }
    Services.userResetPassword(requestData).then((response) => {
      showNotification(constants.RESET_PASSWORD.SUCCESS, 'info')
      push('/users')
    }).catch((error) => {
      showNotification(constants.ERROR.SOMETHING_WENT_WRONG, 'warning')
      push('/users')
    })
    this.handleClose(e)
    return false
  }
  handleClickOpen = (e) => {
    e.stopPropagation()
    this.setState({
      open: true
    })
  }
  handleClose = (e) => {
    e.stopPropagation()
    this.setState({
      open: false
    })
  }

  render() {
    return <div>
      <Modal open={this.state.open}
        handleClose={this.handleClose}
        handleClick={this.handleClick}
        content={constants.RESET_PASSWORD.RESET_PASSWORD_CONFIRMATION_CONTENT}
        title={constants.RESET_PASSWORD.RESET_PASSWORD_CONFIRMATION_TITLE}
      />
      <Button onClick={this.handleClickOpen} color="secondary" size="small">{constants.RESET_PASSWORD.RESET_PASSWORD_BUTTON_TITLE}</Button>
    </div>
  }
}

ResetPasswordButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
}

export default connect(null, {
  showNotification,
  push,
})(ResetPasswordButton)
