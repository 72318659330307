import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
// import { makeStyles } from '@material-ui/core/styles';

import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Button, SimpleShowLayout, TextField, useGetOne, ReferenceField } from 'react-admin';

// const useStyles = makeStyles({
//     field: {
//         // These styles will ensure our drawer don't fully cover our
//         // application when teaser or title are very long
//         '& span': {
//             display: 'inline-block',
//             maxWidth: '40%'
//         }
//     }
// });

const ConsumerQuickPreviewButton = ({ id }) => {
    const [showPanel, setShowPanel] = useState(false);
    // const classes = useStyles();
    const { data } = useGetOne('consumers', id);

    const handleClick = () => {
        setShowPanel(true);
    };

    const handleCloseClick = () => {
        setShowPanel(false);
    };

    return (
        <>
            <Button onClick={handleClick} label="ra.action.show">
                <IconImageEye />
            </Button>
            <Drawer anchor="right" open={showPanel} onClose={handleCloseClick}>
                <div>
                    <Button label="Close" onClick={handleCloseClick}>
                        <IconKeyboardArrowRight />
                    </Button>
                </div>
                <SimpleShowLayout
                    record={data}
                    basepath="/consumers"
                    resource="consumers"
                >
                    <TextField source="id" />
                    <ReferenceField source="organization_id" reference="organizations" >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="name" />
                    <TextField source="address" />
                    <TextField source="email" />
                    <div>
                        <h3>LOGO</h3>
                        {data.logo &&
                            <div style={{backgroundColor: 'lightgrey', borderRadius: '8px', padding: '10px', textAlign: 'center'}}>
                                <p>Logo URL: <a href={`https://${process.env.REACT_APP_CONSUMER_LOGOS_BUCKET}.s3.us-east-2.amazonaws.com/${data.logo}`} target="_blank" rel="noopener noreferrer">{`https://${process.env.REACT_APP_CONSUMER_LOGOS_BUCKET}.s3.us-east-2.amazonaws.com/${data.logo}`}</a></p>
                                <img alt="Consumer Logo" src={`https://${process.env.REACT_APP_CONSUMER_LOGOS_BUCKET}.s3.us-east-2.amazonaws.com/${data.logo}`} style={{maxWidth: '400px', height: 'auto'}} />
                            </div>
                        }
                    </div>
                </SimpleShowLayout>
            </Drawer>
        </>
    );
};

export default ConsumerQuickPreviewButton;
