import React, { useState, useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { ReferenceInput, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import ConsumerModalCreate from './ConsumerModalCreate';
import ConsumerQuickPreviewButton from './ConsumerQuickPreviewButton';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center'
    }
});

const spySubscription = { values: true };

const ConsumerModalInputInline = props => {
    const classes = useStyles();
    const [version, setVersion] = useState(0);
    const { values } = useFormState({ subscription: spySubscription });
    const handleChange = useCallback(() => setVersion(version + 1), [version]);

    return (
        <div className={classes.root}>
            <ReferenceInput key={version} {...props}>
              <SelectInput
                optionText="name"
                optionValue="id" />
            </ReferenceInput>
            <ConsumerModalCreate onChange={handleChange} />
            {!!values.consumer_id && <ConsumerQuickPreviewButton id={values.consumer_id} />}
        </div>
    );
};

export default ConsumerModalInputInline;
