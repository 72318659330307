import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { Services } from './services/Services'
import { styled } from '@material-ui/core/styles';

export class QuickBooks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedIn: false
    }
  }

  componentDidMount(){
    Services.getQBooksLoggedIn().then((response) => {
      this.setState({loggedIn: response.data})
    })
  }

  handleLoginClick = (e) => {
    e.stopPropagation()
    async function getRedirect(){
      const request = new Request(`${process.env.REACT_APP_API_ROOT}/admin/quickbooks/auth`, {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            })
        })
      let response = await fetch(request)
      let redirect = await response.json()
      return redirect
    }
    getRedirect().then(redirect => {
      window.open(redirect)
    })
  }

  handleLogoutClick = (e) => {
    e.stopPropagation()
    Services.postQBooksLogOut().then((response) => {
      if(response.status === 200){
        window.location.reload()
      }
    })
  }

  render() {

  const MyPaper = styled(Paper)({
    background: 'rgb(33,150,243)',
    border: 0,
    borderRadius: 3,
    color: 'white',
    padding: '15px',
  })

  const narrative =
      <MyPaper>
          CERTUS functions depend on the application's connection to Quickbooks.
          Logging in should only be required rarely.
          The application takes care of itself while being connected.
          However, in some rare circumstances, a reestablishing connection is necessary.
      </MyPaper>

  let loginButton = this.state.loggedIn ?
     <div>
        <Button variant="outlined" color="primary" onClick={this.handleLogoutClick}>Click to log out out of Quickbooks</Button>
     </div>
     :
     <div>
        <Button variant="outlined" color="secondary" onClick={this.handleLoginClick}>Login to Quickbooks</Button>
     </div>

  let status = this.state.loggedIn ?
    <MyPaper>QuickBooks Connection Status:<br/><strong style={{color: "green"}}>Connected</strong></MyPaper>
    :
    <MyPaper>QuickBooks Connection Status:<br/><strong style={{color: "red"}}>Not Connected</strong></MyPaper>

    return (
      <div>
        <Grid
          container
          spacing={2}
          direction="column"
		  style={{ alignItems: 'center', justifyContent: 'center' }}
        >
            <Grid item xs={6}>
              <Paper>
                {narrative}
              </Paper>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center' }}>
              {status}
            </Grid>
            <Grid item xs={3}>
             {loginButton}
            </Grid>
            {!this.state.loggedIn && <Grid item>
              <MyPaper square>Kindly refresh this page after logging into QuickBooks</MyPaper>
            </Grid>}
        </Grid>
      </div>)
  }
}
