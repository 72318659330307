import React from 'react'
import { List, Datagrid, TextField, DateField, DateInput } from 'react-admin'
import { Edit,
         Create,
         SimpleForm,
         TextInput,
         Toolbar,
         SaveButton } from 'react-admin'
import MarkdownInput from 'ra-input-markdown';
import { required } from 'ra-core'
import { validSemver } from './lib/validations'

import constants from './lib/constants'

const ReleaseTitle = ({ record }) => {
  return <span>{record ? record.version : 'No Version??'}</span>
}

export const ReleasesList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="version" />
      <TextField source="notes" />
      <DateField source="release_date" locales={constants.LOCALE} />
    </Datagrid>
  </List>
)

const CustomToolbar = (props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
))

export const ReleaseEdit = props => (
  <Edit title={<ReleaseTitle />} {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput disabled source="id" />
      <DateInput source="release_date" />
      <TextInput source="version" validate={[required(constants.FIELD_MANDATORY), validSemver]} />
      <MarkdownInput source="notes" />
    </SimpleForm>
  </Edit>
)

export const ReleaseCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="version" validate={[required(constants.FIELD_MANDATORY), validSemver]} />
      <DateInput source="release_date" />
      <MarkdownInput source="notes" />
    </SimpleForm>
  </Create>
)
