let constants = {
  RESOURCE_SAMPLES: 'samples',
  RESOURCE_SAMPLE_TYPES: 'sample-types',
  RESOURCE_USERS: 'users',
  RESOURCE_ORGANIZATIONS: 'organizations',
  RESOURCE_ORGANIZATION_OFFICERS: 'organization_officers',
  RESOURCE_CONSUMERS: 'consumers',
  RESOURCE_DASHBOARD: 'dashboard',
  RESOURCE_OPPORTUNITIES: 'opportunities',
  RESOURCE_MACHINES: 'machines',
  RESOURCE_ROLES: 'roles',
  RESOURCE_RELEASES: 'releases',
  RESOURCE_CONSUMER_PURCHASES: 'consumer_purchases',
  RESOURCE_CONSUMER_SUBMISSIONS: 'consumer_submissions',
  RESOURCE_CONSUMERS_CTS_SUMMARIES: 'consumers_cts_summaries',
  RESOURCE_QUICKBOOKS:'quickbooks',
  RESOURCE_CERTUS_TOKEN_COSTS: 'certus_token_costs',
  RESOURCE_CERTUS_TOKEN_REGIONS: 'certusregions',
  RESOURCE_ASSIGN_JURISDICTION: 'tokenbanks',

  USER: {
    ACTIVATE_SUCCESS: 'Account activted successfully',
    DEACTIVATE_SUCCESS: 'Account de-activted successfully',
    ACTIVATE_CONFIRMATION_TITLE: 'Are you sure want to Activate the user?',
    ACTIVATE_CONFIRMATION_CONTENT: 'Are you sure want to Activate the user?',
    DEACTIVATE_CONFIRMATION_TITLE: 'Are you sure want to Inactivate the user?',
    DEACTIVATE_CONFIRMATION_CONTENT: 'User will not be able to access the application if account is Inactivated.',
    ACTIVATE_BUTTON_TITLE: 'Active',
    DEACTIVATE_BUTTON_TITLE: 'Inactive',
    PASSWORD_STRENGTH: 'Password should be minimum 8 characters, should have minimum 1 Alpha 1 numeric and 1 Capitalization'
  },
  LOGOUT: {
    LOGOUT_FROM_ALL_DEVICES: 'Logout From All Devices',
    LOGOUT_CONFIRMATION_TITLE: 'Are you sure you want to log out from everywhere?',
    SUCCESS: 'Logged out successfully'
  },
  ERROR: {
    SOMETHING_WENT_WRONG: 'Something went wrong! Please try again'
  },
  RESET_PASSWORD: {
    SUCCESS: 'Email sent may be in your spam folder please verify and add the Email Address to your contacts for future use',
    RESET_PASSWORD_BUTTON_TITLE: 'Reset Password',
    RESET_PASSWORD_CONFIRMATION_TITLE: 'Are you sure want to reset the password?',
    RESET_PASSWORD_CONFIRMATION_CONTENT: 'E-mail with reset password link will be send to user\'s registered E-mail account',
  },
  ORGANIZATION: {
    APPROVE_SUCCESS: 'Organization approved successfully',
    DISAPPROVE_SUCCESS: 'Organization Disapproved successfully',
    APPROVE_CONFIRMATION_TITLE: 'Are you sure want to Approve the organization?',
    APPROVE_CONFIRMATION_CONTENT: 'Are you sure want to Approve the organization?',
    DISAPPROVE_CONFIRMATION_TITLE: 'Are you sure want to Disapprove the organization?',
    DISAPPROVE_CONFIRMATION_CONTENT: 'Are you sure want to Disapprove the organization?',
    APPROVE_BUTTON_TITLE: 'Approve',
    DISAPPROVE_BUTTON_TITLE: 'Disapprove',
    CREATE_FROM_ZOHO: 'Create from Zoho',
    NO_ZOHO_ACCOUNT_FOUND: 'No Organization Available'
  },
  SAMPLE_TYPE: {
    EDIT_BUTTON_TITLE: 'EDIT',
    EDIT_HEADER_TITLE: 'Edit Sample Type',
    UPDATE_SUCCESS: 'Sample type updated successfully'
  },
  FIELD_MANDATORY: 'Please fill the field',
  LOCALE: 'en-us'
}

export default constants
