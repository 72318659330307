import axios from "../axios"
export const Services = {
  userActiveToggle,
  getMachines,
  userResetPassword,
  getRoles,
  organizationApproveToggle,
  logoutFromAllDevices,
  getZohoOrganizations,
  editSampleTypes,
  search,
  getOrgTestSummary,
  getJurisdictions,
  getQBooksLoggedIn,
  postQBooksLogOut
}
function userActiveToggle(id, requestData) {
  return axios().patch('/admin/users/' + id, requestData)
}
function getMachines(requestData) {
  return axios().get('/admin/machine-details/', {params:requestData})
}
function getOrgTestSummary() {
  return axios().get('/admin/organization_test_summaries', {params:{filter: {is_active: true}}})
}
function userResetPassword(requestData) {
  return axios().post('/admin/users/reset-password', requestData)
}
function getRoles() {
  return axios().get('/admin/roles?no_pagination=true')
}
function organizationApproveToggle(id, requestData) {
  return axios().patch('/admin/organizations/' + id, requestData)
}
function logoutFromAllDevices(id) {
  return axios().delete('/admin/users/' + id + '/sessions')
}
function getZohoOrganizations() {
  return axios().get('/admin/zoho/organizations')
}
function editSampleTypes(id, requestData) {
  return axios().patch('/admin/sample-types/' + id, requestData)
}
function search(model, term) {
  return axios().get(`/admin/${model}s/search/${term}`)
}

function getJurisdictions(){
  return axios().get('admin/certus_token_costs')
}

function getQBooksLoggedIn(){
  return axios().get('admin/quickbooks')
}

function postQBooksLogOut(){
  return axios().post('admin/quickbooks/logout')
}
