import React, {Component} from 'react'
import { TabbedShowLayout, Tab } from 'react-admin'
// import GridLayout from 'react-grid-layout'

import { OrgTestSummaryDashboard } from './dashboard/OrgTestSummaryDashboard'
import { MachinesDashboard } from './dashboard/MachinesDashboard'

import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"

export class Dashboard extends Component {
  render() {
    // var layout = [
    //   {i: 'a', x: 0, y: 0, w: 2, h: 30},
    //   {i: 'b', x: 2, y: 0, w: 2, h: 30},
    //   {i: 'c', x: 0, y: 30, w: 2, h: 15},
    //   {i: 'd', x: 2, y: 30, w: 2, h: 15}
    // ]
    return (
      // temporarily commented out grid for controlling layout of dashboard since currently only has 2 tables and they're large
      // reference: https://react-grid-layout.github.io/react-grid-layout/examples/1-basic.html

      // <GridLayout className="layout" layout={layout} cols={2} rowHeight={23} width={window.innerWidth} bulkActionButtons={false}>
      //   <div id="orgTestSummary" key="a">
      //     <OrgTestSummaryDashboard/>
      //   </div>
      //   <div key="b"></div>
      //   <div key="c">
      //     <MachinesDashboard/>
      //   </div>
      //   <div key="d"></div>
      // </GridLayout>
      <div id="dashboard-container">
        <TabbedShowLayout>
          <Tab label="Sample Tests">
            <OrgTestSummaryDashboard/>
          </Tab>
          <Tab label="Machines">
            <MachinesDashboard/>
          </Tab>
        </TabbedShowLayout>
		<p><small>Version: {process.env.REACT_APP_VERSION}</small></p>
      </div>
    )
  }
}
