import React, {
  cloneElement
} from 'react'
import { List, Datagrid, TextField, DateField,
         useListContext, TopToolbar, sanitizeListRestProps, ReferenceInput, SelectInput } from 'react-admin'
import { Edit,
         Create,
         SimpleForm,
         TextInput,
         NumberInput,
         Toolbar,
         CreateButton,
         ExportButton,
         SearchInput,
         Filter,
         SaveButton } from 'react-admin'
import { required, minValue } from 'ra-core'

import constants from '../lib/constants'
import ConsumerReferenceInputInline from './ConsumerReferenceInputInline'

const ConsumerPurchaseListActions = props => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, basePath, showFilter, total } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={20000}
      />
    </TopToolbar>
  );
};

const ConsumerPurchaseListFilter = (props) => (
  <Filter {...props}>
    <SearchInput placeholder="Filter by Consumer's Name" source="consumer" alwaysOn />
  </Filter>
)

export const ConsumerPurchaseList = (props) => (
  <List {...props}
    actions={<ConsumerPurchaseListActions />}
    filters={<ConsumerPurchaseListFilter />}
    sort={{ field: 'purchase_order_number', order: 'DESC' }}
    perPage={25}
    bulkActionButtons={false} >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="consumer" />
        <TextField source="purchase_order_number" label="Purchase Order #" />
        <TextField source="twenty_four_hour_quantity" label="Quantity: 24 Hour" />
        <TextField source="seventy_two_hour_quantity" label="Quantity: 72 Hour" />
        <DateField source="updated_at" locales={constants.LOCALE} />
        <DateField source="created_at" locales={constants.LOCALE} />
      </Datagrid>
  </List>
)

const CustomToolbar = (props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
))

const ConsumerPurchaseTitle = ({ record }) => {
  return <span>{record.consumer}: {record.purchase_order_number}</span>
}

export const ConsumerPurchaseEdit = props => {
  return (
  <Edit title={<ConsumerPurchaseTitle />} {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
    <TextInput disabled source="id" />
      <ReferenceInput source="consumer_id" reference="consumers" label="Consumer" perPage={-1} validate={required('Please select consumer')}>
        <SelectInput
          optionText="name"
          optionValue="id" />
      </ReferenceInput>
      <NumberInput source="purchase_order_number" label="Purchase Order Number" min={0} step={1} validate={[required(constants.FIELD_MANDATORY),minValue(0, "Must be >= 0")]} />
      <NumberInput source="twenty_four_hour_quantity" label="Quantity: 24 Hour" min={0} step={1} validate={[required(constants.FIELD_MANDATORY),minValue(0, "Must be >= 0")]} />
      <NumberInput source="seventy_two_hour_quantity" label="Quantity: 72 Hour" min={0} step={1} validate={[required(constants.FIELD_MANDATORY),minValue(0, "Must be >= 0")]} />
    </SimpleForm>
  </Edit>
)}

export const ConsumerPurchaseCreate  = (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirect={'/consumer_purchases'}>
        <TextInput disabled source="id" />
        <ConsumerReferenceInputInline
          source="consumer_id"
          reference={constants.RESOURCE_CONSUMERS}
          validate={required('Please select consumer')}
          label="Consumer"
          perPage={-1}
        />
        <NumberInput source="purchase_order_number" label="Purchase Order Number" min={0} step={1} validate={[required(constants.FIELD_MANDATORY),minValue(0, "Must be >= 0")]} />
        <NumberInput source="twenty_four_hour_quantity" label="Quantity: 24 Hour" min={0} step={1} validate={[required(constants.FIELD_MANDATORY),minValue(0, "Must be >= 0")]} />
        <NumberInput source="seventy_two_hour_quantity" label="Quantity: 72 Hour" min={0} step={1} validate={[required(constants.FIELD_MANDATORY),minValue(0, "Must be >= 0")]} />
      </SimpleForm>
    </Create>
  )
}

