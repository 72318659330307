import React from 'react'
import { Admin, Resource, fetchUtils } from 'react-admin'
import authProvider from './authProvider'
import simpleRestProvider from './DataProvider'
import { Dashboard } from './Dashboard'
import { OrganizationList, OrganizationEdit, OrganizationCreate } from './organizations'
import { QuickBooks } from './quickbooks'
import { Certus_token_costList, CertusTokenCreate } from './certusTokenCost'
import { OrganizationOfficerList, OrganizationOfficerEdit, OrganizationOfficerCreate } from './organization_officers'
import { ConsumerList, ConsumerEdit, ConsumerCreate } from './consumers/consumers'
import { ConsumerPurchaseList, ConsumerPurchaseEdit, ConsumerPurchaseCreate } from './consumers/consumer_purchases'
import { ConsumerSubmissionList, ConsumerSubmissionEdit, ConsumerSubmissionCreate } from './consumers/consumer_submissions'
import { ConsumersCtsSummariesList } from './consumers/consumers_cts_summaries'
import { UserList, UserEdit, UserCreate } from './users'
import { MachineList, MachineEdit, MachineCreate } from './machines'
import { SampleList, SampleEdit, SampleCreate } from './samples'
import { ReleasesList, ReleaseEdit, ReleaseCreate } from './releases'
import { SampleTypesList, SampleTypesCreate, SampleTypesEdit } from './sample_types'
import { CertusRegionList, CertusRegionCreate } from './CertusRegionList'
import { TokenbankList, TokenbankEdit, TokenbankCreate } from './assignTokens'
import PersonOutline from '@material-ui/icons/PersonOutline'
import Group from '@material-ui/icons/Group'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import Spa from '@material-ui/icons/Spa'
import DeviceIcon from '@material-ui/icons/Devices'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PolicyIcon from '@material-ui/icons/Policy';
import PolymerIcon from '@material-ui/icons/Polymer'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import InputIcon from '@material-ui/icons/Input'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import constants from './lib/constants'
import MyLayout from './layout/layout'

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const access_token = localStorage.getItem('access_token')
  options.headers.set('Authorization', `Bearer ${access_token}`)
  return fetchUtils.fetchJson(url, options)
}

export const dataProvider = simpleRestProvider(process.env.REACT_APP_API_ROOT, httpClient)

const App = () => (
  <Admin layout={MyLayout} authProvider={authProvider} dataProvider={dataProvider} >
    <Resource name={constants.RESOURCE_DASHBOARD} list={Dashboard} icon={DashboardIcon} />
    <Resource name={constants.RESOURCE_ORGANIZATIONS} list={OrganizationList} edit={OrganizationEdit} create={OrganizationCreate} icon={Group} />
    <Resource name={constants.RESOURCE_ORGANIZATION_OFFICERS} list={OrganizationOfficerList} edit={OrganizationOfficerEdit} create={OrganizationOfficerCreate} icon={PolicyIcon} options={{ label: 'Organization Officers' }} />
    <Resource name={constants.RESOURCE_USERS} list={UserList} edit={UserEdit} create={UserCreate} icon={PersonOutline} />
    <Resource name={constants.RESOURCE_RELEASES} list={ReleasesList} edit={ReleaseEdit} create={ReleaseCreate} />
    <Resource name={constants.RESOURCE_ROLES} />
    <Resource name={constants.RESOURCE_SAMPLES} list={SampleList} edit={SampleEdit} create={SampleCreate} icon={Spa} />
    <Resource name={constants.RESOURCE_SAMPLE_TYPES} list={SampleTypesList} create={SampleTypesCreate} edit={SampleTypesEdit} icon={PolymerIcon} options={{ label: 'Sample Types' }} />
    <Resource name={constants.RESOURCE_MACHINES} list={MachineList} edit={MachineEdit} create={MachineCreate} icon={DeviceIcon} />
    <Resource name={constants.RESOURCE_CONSUMERS} list={ConsumerList} edit={ConsumerEdit} create={ConsumerCreate} icon={SupervisedUserCircleIcon} />
    <Resource name={constants.RESOURCE_CONSUMER_PURCHASES} list={ConsumerPurchaseList} edit={ConsumerPurchaseEdit} create={ConsumerPurchaseCreate} icon={ShoppingCartIcon} options={{ label: 'Consumer Purchases' }} />
    <Resource name={constants.RESOURCE_CONSUMER_SUBMISSIONS} list={ConsumerSubmissionList} edit={ConsumerSubmissionEdit} create={ConsumerSubmissionCreate} icon={InputIcon} options={{ label: 'Consumer Submissions' }} />
    <Resource name={constants.RESOURCE_CONSUMERS_CTS_SUMMARIES} list={ConsumersCtsSummariesList} icon={AccountBalanceIcon} options={{ label: 'CTS Balances' }} />
    <Resource name={constants.RESOURCE_CERTUS_TOKEN_REGIONS} list={CertusRegionList} create={CertusRegionCreate} icon={AccountBalanceIcon} options={{ label: 'Token Regions' }}/>
    <Resource name={constants.RESOURCE_CERTUS_TOKEN_COSTS} list={Certus_token_costList} create={CertusTokenCreate} options={{ label: 'Token Jurisdicitons' }}/>
    <Resource name={constants.RESOURCE_ASSIGN_JURISDICTION} list={TokenbankList} edit={TokenbankEdit} create={TokenbankCreate} icon={AccountBalanceIcon} options={{ label: 'Assign Jurisdiction' }}/>
    <Resource name={constants.RESOURCE_QUICKBOOKS} list={QuickBooks} icon={AccountBalanceIcon} options={{ label: 'Quickbooks' }}/>
    <Resource name="assigntokenbank"/>
    {/*<Resource name="certus_token_costs"/>*/}
  </Admin>
)

export default App
