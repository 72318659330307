import React from 'react'
import {List,Datagrid, TextField, NumberField, Create, SimpleForm, NumberInput, TextInput, required } from 'react-admin'
import { positiveCharge } from './lib/validations'

export const CertusRegionList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <NumberField source="region_token_cost" label="Region Price" textAlign='left' options={{ style: 'currency', currency: 'USD' }}/>
        </Datagrid>
    </List>
);

export class CertusRegionCreate extends React.Component{
    render(){
        return(
            <Create {...this.props}>
                <SimpleForm>
                    <TextInput source='Region_Name' validate={[required()]} />
                    <NumberInput source='Region_Cost' label="Region Price"
						helperText='The sum of this price and the Jurisdiction Price is the total charge per token to the client (Total Token Price)'
						min={0} step={.01} validate={[required(), positiveCharge]} />
                </SimpleForm>
            </Create>
        )
    }
}
