import React,
  {Component} from 'react'
import { List,
  Datagrid,
  Filter,
  TextField,
  BooleanField,
  DateField,
  downloadCSV,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput} from 'react-admin'
import { required } from 'ra-core'
import jsonExport from 'jsonexport/dist'
import jsonFormat from 'json-format'

const jsonToTextArea = (json) => {
  if (json) {
    return jsonFormat(json)
  } else {
    return ''
  }
}

const textAreaToJson = (json) => {
  if (json) {
    return JSON.parse(json)
  } else {
    return null
  }
}

const NameTitle = ({ record }) => {
  return <span>{record ? record.name : 'Nameless'}</span>
}

const exporter = machines => {
  const machinesForExport = machines.map(machine => {
    let machineForExport = machine
    machineForExport['organization'] = machine.organization.name
    return machineForExport
  })
  jsonExport(machinesForExport, {
    headers: ['name', 'organization', 'tv_login', 'tv_password']
  }, (err, csv) => {
    downloadCSV(csv, 'machines')
  })
}
// const MachineListFilter = (props) => (
//   <Filter {...props}>
//     <ReferenceInput id="machineFilter" resource ="machines" source = "organization_id" reference = "organizations" perPage = {-1} alwayson>
//       <SelectInput label = "Organization" source = "organization_id" reference = "organizations"  />
//     </ReferenceInput>
//   </Filter>
// )

const TextArea = ({ record = {} }) => <textarea id={record.tv_login} value={record.tv_password?record.tv_password:''} readOnly/>
TextArea.defaultProps = { label: 'TV Password' };

const SampleListFilter = (props) => (
    <Filter {...props}>
      <ReferenceInput source="organization_id" reference="organizations" perPage={-1} alwaysOn>
        <SelectInput  label="Organization" source="organization_id" reference="organizations" />
      </ReferenceInput>
    </Filter>
)

export const SubField = props => (
  (
    <TextField {...props} source = {props.source+'.name'} />
  )
)

export class MachineList extends Component {
  render() {
    return (
      <List {...this.props} exporter={exporter} filters = {<SampleListFilter /> } perPage={25} >
        <Datagrid rowClick="edit">
          <TextField source="name" />
          <SubField source="organization" />
          <BooleanField source="agilent_license" label="Agilent License" />
          <SubField source="user" label="Assigned to User" />
          <DateField source="updated_at" />
          <DateField source="created_at" />
        </Datagrid>
      </List>
    )
  }
}

export class MachineEdit extends Component {
  render() {
    return (
      <Edit title={<NameTitle />} {...this.props}>
        <SimpleForm>
          <TextInput disabled source="id" />
          <TextInput source="name" validate={required('Please fill the field')} />
          <ReferenceInput source="organization.id" reference="organizations" perPage={-1} validate={required('Please fill the field')} >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput fullWidth source="pillar_data" multiline resettable format={jsonToTextArea} parse={textAreaToJson} />
          <BooleanInput label="Agilent Licensed" source="agilent_license" />
          <ReferenceInput source="user.id" label="Assigned to User" reference="users" perPage={-1} allowEmpty={true}>
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    )
  }
}
const redirect = (basePath, id, data) => `/machines`

export class MachineCreate extends React.Component {
  render() {
    return (
      <Create {...this.props}>
        <SimpleForm redirect={redirect}>
          <TextInput source="name" validate={required('Please fill the field')} />
          <ReferenceInput source="organization_id" reference="organizations" perPage={-1} validate={required('Please fill the field')}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput fullWidth source="pillar_data" multiline resettable format={jsonToTextArea} parse={textAreaToJson} />
          <BooleanInput label="Agilent Licensed" source="agilent_license" />
          <ReferenceInput source="user_id" label="Assigned to User" reference="users" perPage={-1} allowEmpty={true}>
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    )
  }
}
