import React from 'react'
import { List, Datagrid, TextField, DateField, ReferenceInput, SelectInput } from 'react-admin'
import { Edit, Create, SimpleForm, TextInput, BooleanInput, Toolbar, SaveButton, BooleanField } from 'react-admin'
import { required } from 'ra-core'
import constants from './lib/constants'
let roles = []
const OrganizationTitle = ({ record }) => {
  return <span>{record ? record.name : 'Nameless Organization'}</span>
}

export const OrganizationList = props => (
  <List {...props} bulkActionButtons={false} perPage={25} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <BooleanField source="is_active" label="Active" />
      <DateField source="updated_at" locales={constants.LOCALE} />
      <DateField source="created_at" locales={constants.LOCALE} />
    </Datagrid>
  </List>
)

const CustomToolbar = (props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
))

const redirect = `/organizations`

export const OrganizationEdit = props => (
  <Edit title={<OrganizationTitle />} {...props}>
    <SimpleForm redirect={redirect} toolbar={<CustomToolbar />}>
      <TextInput disabled source="id" />
      <TextInput source="name" validate={required(constants.FIELD_MANDATORY)} />
      <TextInput source="email" />
      <TextInput source="contact_no" label="Phone" />
      <TextInput multiline source="address" />
      <TextInput source="ag_dept" style={{ display: 'inline', float: 'left' }}/>
      <TextInput source="ag_number" />
      <TextInput source="iso_accreditor" style={{ display: 'inline', float: 'left' }} />
      <TextInput source="iso_accreditor_number" />
      <TextInput source="dea_number" />
      <TextField source="org_certus_bank.quantity" />
      <TextField source="org_certus_bank.region" />
      <TextField source="org_certus_bank.juri" />

      <BooleanInput source="is_active" label="Active" />
    </SimpleForm>
  </Edit>
)

export class OrganizationCreate extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Create {...this.props}>
        <SimpleForm redirect={redirect}>
            <TextInput source="name" validate={required(constants.FIELD_MANDATORY)} />
            <TextInput source="email" validate={required(constants.FIELD_MANDATORY)} />
            <TextInput source="contact_no" label="Phone" validate={required(constants.FIELD_MANDATORY)} />
            <TextInput multiline source="address" />
            <TextInput source="ag_dept" style={{ display: 'inline', float: 'left' }}/>
            <TextInput source="ag_number" />
            <TextInput source="iso_accreditor" style={{ display: 'inline', float: 'left' }} />
            <TextInput source="iso_accreditor_number" />
            <TextInput source="dea_number" />
            <BooleanInput source="is_active" label="Active" defaultValue={true} />
        </SimpleForm>
      </Create>
    )
  }
}
