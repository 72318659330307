import { stringify } from 'query-string'
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  GET_MANY,
  GET_MANY_REFERENCE,
  fetchUtils,
  UPDATE_MANY,
  DELETE_MANY
} from 'react-admin'
import constants from './lib/constants'
var __assign = (this && this.__assign) || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i]
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
        t[p] = s[p]
    }
    return t
  }
  return __assign.apply(this, arguments)
}
// Object.defineProperty(export, "__esModule", { value: true })

function dataProviderHook(apiUrl, type, resource, params) {
  apiUrl = process.env.REACT_APP_API_ROOT + '/admin'
  if (resource === constants.RESOURCE_OPPORTUNITIES) {
    if (type === 'GET_LIST') {
      apiUrl = 'https://my-json-server.typicode.com/rahulggk/cann-dummy-api-server'
    }
  }
  if (resource === constants.RESOURCE_USERS) {
    if (type === 'GET_LIST') {

    }
    if (type === 'CREATE') {
      if (params.data.role) params.data.role_id = params.data.role.id
      if (params.data.organization) params.data.organization_id = params.data.organization.id
      params.data = { user: params.data }
    }
    if (type === 'UPDATE') {
      if (params.data.role) params.data.role_id = params.data.role.id
      if (params.data.organization) params.data.organization_id = params.data.organization.id
      params.data = { user: params.data }
    }
  }
  return { apiUrl, type, resource, params }
}

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */

export default (apiUrl, httpClient) => {
  if (httpClient === void 0) { httpClient = fetchUtils.fetchJson }
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  var convertDataRequestToHTTP = function (type, resource, params) {
    var _a
    var url = ''
    var options = {}
    let hook = dataProviderHook(apiUrl, type, resource, params)
    apiUrl = hook.apiUrl
    resource = hook.resource
    params = hook.params
    let query, page, perPage, order, field
    switch (type) {
      case GET_LIST: {
        var _b = params.pagination
        page = _b.page
        perPage = _b.perPage
        var _c = params.sort
        field = _c.field
        order = _c.order
        query = {
          sort: JSON.stringify([field, order]),
          sortColumn: field,
          sortOrder: order,
          startIndex: (page - 1) * perPage,
          endIndex: page * perPage,
          range: JSON.stringify([
            (page - 1) * perPage,
            page * perPage,
          ]),
          filter: JSON.stringify(params.filter),
        }
        if (perPage < 0) {
          query = {
            no_pagination: true,
          }
        }
        url = apiUrl + "/" + resource + "?" + stringify(query)
        break
      }
      case GET_ONE:
        url = apiUrl + "/" + resource + "/" + params.id
        break
      case GET_MANY: {
        query = {
          filter: JSON.stringify({ id: params.ids }),
        }
        url = apiUrl + "/" + resource + "?" + stringify(query)
        break
      }
      case GET_MANY_REFERENCE: {
        var _d = params.pagination
        page = _d.page
        perPage = _d.perPage
        var _e = params.sort
        field = _e.field
        order = _e.order
        query = {
          sort: JSON.stringify([field, order]),
          sortColumn: field,
          sortOrder: order,
          startIndex: (page - 1) * perPage,
          endIndex: page * perPage,
          range: JSON.stringify([
            (page - 1) * perPage,
            page * perPage,
          ]),
          filter: JSON.stringify(__assign({}, params.filter, (_a = {}, _a[params.target] = params.id, _a))),
        }
        if (perPage < 0) {
          query = {
            no_pagination: true,
          }
        }
        url = apiUrl + "/" + resource + "?" + stringify(query)
        break
      }
      case UPDATE:
        url = apiUrl + "/" + resource + "/" + params.id
        options.method = 'PATCH'
        options.body = JSON.stringify(params.data)
        break
      case CREATE:
        url = apiUrl + "/" + resource
        options.method = 'POST'
        options.body = JSON.stringify(params.data)
        break
      case DELETE:
        url = apiUrl + "/" + resource + "/" + params.id
        options.method = 'DELETE'
        break
      default:
        throw new Error("Unsupported fetch action type " + type)
    }
    return { url: url, options: options }
  }
  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  var convertHTTPResponse = function (response, type, resource, params) {
    var json = response.json
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
      case GET_MANY:
        // if (!headers.has('content-range')) {
        //   throw new Error('The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?');
        // }
        return {
          data: json.data,
          total: parseInt(json.meta.total_count),
        }
      case CREATE:
        return { data: __assign({}, params.data, { id: json.id }) }
      default:
        return { data: json }
    }
  }
  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return function (type, resource, params) {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(params.ids.map(function (id) {
        return httpClient(apiUrl + "/" + resource + "/" + id, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
      })).then(function (responses) {
        return ({
          data: responses.map(function (response) { return response.json }),
        })
      })
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(params.ids.map(function (id) {
        return httpClient(apiUrl + "/" + resource + "/" + id, {
          method: 'DELETE',
        })
      })).then(function (responses) {
        return ({
          data: responses.map(function (response) { return response.json }),
        })
      })
    }
    var _a = convertDataRequestToHTTP(type, resource, params), url = _a.url, options = _a.options
    return httpClient(url, options).then(function (response) {
      return convertHTTPResponse(response, type, resource, params)
    })
  }
}
