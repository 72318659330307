import React from 'react'
import { List, Datagrid, TextField, TextInput, Create, SimpleForm, NumberField,
	NumberInput, SelectInput, ReferenceInput, required } from 'react-admin'
import { positiveCharge } from './lib/validations'


export const Certus_token_costList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="region" />
            <TextField source="juri" label="Jurisdiction"/>
            <NumberField source="region_base" label="Region Price" options={{ style: 'currency', currency: 'USD' }}/>
            <NumberField source="juri_cost" label="Jurisdiction Price" options={{ style: 'currency', currency: 'USD' }}/>
            <NumberField source="locale_cost" label="Total Token Price" options={{ style: 'currency', currency: 'USD' }}/>
            <NumberField source='cog' label="CERTUS Cost To Us" options={{ style: 'currency', currency: 'USD' }}/>
        </Datagrid>
    </List>
);


export class CertusTokenCreate extends React.Component{
	render(){
		return(
			<Create {...this.props}>
				<SimpleForm>
					<ReferenceInput source="regions_id" reference="certusregions" validate={[required()]} >
						<SelectInput label="Region" source="regions_nameCertus" reference="regions" />
					</ReferenceInput>
					<TextInput source='jurisdiction_Name' label="Jurisdiction Name" validate={[required()]} />
					<NumberInput source='juri_cost' label="Jurisdiction Price"
						helperText='The sum of this price and the Region Price is the total charge per token to the client (Total Token Price)'
						min={0} step={.01} validate={[required(), positiveCharge]} />
					<NumberInput source='cog' label="CERTUS Cost To Us"
						helperText="This is the cost we're charged by SICPA per CERTUS hash (token used)"
						min={0} step={.01} validate={[required(), positiveCharge]} />
				</SimpleForm>
			</Create>
		)
	}
}
