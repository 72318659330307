import axios from 'axios'

axios.defaults.headers.common['Accept'] = 'application/json'
const getAxiosInstance = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT,
  })
  instance.interceptors.request.use(reqConfig => {
    if (localStorage.getItem('access_token'))
      reqConfig.headers.authorization = 'Bearer ' + localStorage.getItem('access_token')
    return reqConfig
  },
    err => Promise.reject(err),
  )
  instance.interceptors.response.use(response => {
    return response
  }, err => {
    return Promise.reject(err.response)
  },
  )
  return instance
}

export default getAxiosInstance
